
.blob-l {
    left: -50rem;
    position: fixed;
}

.blob-r {
    right: -52rem;
    position: fixed;
}

#blob-l-1 {
    background-color: #AD2628;
    border-radius: 23% 77% 21% 79% / 44% 53% 47% 56%;
    width: 35vw; height: 35vw;
    z-index: -1;  


    animation: morph1 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
}

#blob-l-2 {
    opacity: 0.3;
    background-color: #AD2628;
    border-radius: 23% 77% 21% 79% / 44% 53% 47% 56%;
    width: 35vw; height: 35vw;
    z-index: -1;


    animation: morph2 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
}

#blob-r-1 {
    background-color: #AD2628;
    border-radius: 23% 77% 21% 79% / 44% 53% 47% 56%;
    width: 35vw; height: 35vw;
    z-index: -1;  


    animation: morph3 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
}

#blob-r-2 {
    opacity: 0.3;
    background-color: #AD2628;
    border-radius: 23% 77% 21% 79% / 44% 53% 47% 56%;
    width: 35vw; height: 35vw;
    z-index: -1;


    animation: morph4 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
}


@keyframes morph1 {
    0% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(0,0,0) scale(1, 1) rotateZ(0deg);
    }
    50% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(0,0,0) scale(1.1, 1.2) rotateZ(0deg);
    }
    70% {
        border-radius: 19% 81% 24% 76% / 53% 56% 44% 47%;
        transform: translate(10rem,-30rem) scale(1.5, 1.5) rotateZ(180deg);
    }
    90% {
        transform: translate(11rem,-30rem);
    }
    100% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(5rem,-20rem,0) scale(1.1, 1) rotateZ(360deg);
    }
}

@keyframes morph2 {
    0% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(0,0,0) scale(1, 1) rotateZ(0deg);
    }
    55% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(14rem,0,0) scale(1, 1) rotateZ(0deg);
    }
    75% {
        border-radius: 19% 81% 24% 76% / 53% 56% 44% 47%;
        transform: translate(14rem,-30rem) scale(1.6, 1.2) rotateZ(180deg);
    }
    95% {
        transform: translate(15rem,-30rem);
    }
    100% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(5rem,-20rem,0) scale(1, 1) rotateZ(360deg);
    }
}


@keyframes morph3 {
    0% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(0,0,0) scale(1, 1) rotateZ(0deg);
    }
    50% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(0,0,0) scale(1.1, 1.2) rotateZ(0deg);
    }
    70% {
        border-radius: 19% 81% 24% 76% / 53% 56% 44% 47%;
        transform: translate(-10rem,-30rem) scale(1.5, 1.5) rotateZ(-180deg);
    }
    90% {
        transform: translate(-11rem,-30rem);
    }
    100% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(-5rem,-20rem,0) scale(1.1, 1) rotateZ(-360deg);
    }
}

@keyframes morph4 {
    0% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(0,0,0) scale(1, 1) rotateZ(0deg);
    }
    55% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(-14rem,0,0) scale(1, 1) rotateZ(0deg);
    }
    75% {
        border-radius: 19% 81% 24% 76% / 53% 56% 44% 47%;
        transform: translate(-14rem,-30rem) scale(1.6, 1.2) rotateZ(180deg);
    }
    95% {
        transform: translate(-15rem,-30rem);
    }
    100% {
        border-radius: 79% 21% 83% 17% / 78% 17% 83% 22%;
        transform: translate(-5rem,-20rem,0) scale(1, 1) rotateZ(360deg);
    }
}
