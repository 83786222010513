.bold-hr {
    border-top: 2px solid;
    opacity: 100%;
}

.yt {
    width: 100%;
    min-height: 500px;
}

a {
    color: var(--bs-body-color);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    color: var(--bs-body-color);
}
