.img-bg
{
    text-align: center;
    font-size: 1.3rem;
    background-image: url("../../img/bg_img_dark.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    min-height: 500px;
    color: white;
    text-shadow: 2px -2px 7px #313131;

    .logo
    {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    .desc
    {
        margin-left: auto;
        margin-right: auto;
        width: 75%;
    }
}

