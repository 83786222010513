.categ {
    font-size: 3em;
    padding-bottom: 1rem;
}

.extrah {
    font-size: 2em;
    line-height: 2.4rem;
    padding-bottom: 1rem;
    margin-right: 60px;
}

.price {
    font-size: 1.2em;
    padding: 1.2rem;
}