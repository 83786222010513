.red_bg
{
    background-image: url("../../img/red_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50%;
    width: 100%;
    min-height: 500px;
    margin-bottom: 120px;
    padding-top: 175px;
    color: white;
    font-size: 1.1rem;
}